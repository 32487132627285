import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import LinksPage from 'src/pages/Links/Links';
import GoogleSignIn from 'src/pages/Auth/components/GoogleSignIn';
import LinkEditPage from 'src/pages/LinkEdit';
import App from 'src/App';
import ErrorPage from 'src/pages/Error';
import MainPage from 'src/pages/Main';
import AccountPage from 'src/pages/Account';
import LinkPage from 'src/pages/Link';
import AuthPage from 'src/pages/Auth';
import QrPrintPage from 'src/pages/QRPrint';
import ProtectedRoute from 'src/hoc/ProtectedRoute';
import OrderPage from 'src/pages/Order';
import TermsPage from 'src/pages/Terms';
import PrivacyPage from 'src/pages/Privacy';
import ContactsPage from 'src/pages/Contacts';
import LoggedInRoute from 'src/hoc/LoggedInRoute';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <LoggedInRoute><MainPage /></LoggedInRoute>,
      },
      {
        path: 'account',
        element: <ProtectedRoute><AccountPage /></ProtectedRoute>,
      },
      {
        path: 'links',
        element: <ProtectedRoute><LinksPage /></ProtectedRoute>,
      },
      {
        path: 'link-edit/:id',
        element: <ProtectedRoute><LinkEditPage /></ProtectedRoute>,
      },
      {
        path: 'order',
        element: <OrderPage />,
      },
      {
        path: 'terms-and-conditions',
        element: <TermsPage />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPage />,
      },
      {
        path: 'contacts',
        element: <ContactsPage />,
      },
      {
        path: 'auth',
        element: <AuthPage />,
        children: [
          {
            path: 'google-callback',
            element: <GoogleSignIn />,
          },
          {
            path: ':alias',
            element: <AuthPage />,
          },
        ],
      },
    ],
  },
  {
    path: '/:alias',
    element: <LinkPage />,
  },
  {
    path: 'print/:count',
    element: <ProtectedRoute><QrPrintPage /></ProtectedRoute>,
  },
]);

export default router;
