import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  SvgIcon,
  Typography,
} from '@mui/material';
import UserCard from 'src/pages/Link/components/UserCard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QrCodeView from 'src/components/QrCodeView';
import { UserBio } from 'src/types/user';

function Hero() {
  const { t } = useTranslation();

  const demoUser: UserBio = {
    link: {
      value: 'https://cattlet.com',
      label: t('Your Website'),
    },
    cardNumber: '9999999999999999',
    phone: '380999999999',
    name: 'Lorem ipsum dolor sit amet.',
    socialLinks: [
      {
        id: '1',
        type: 'place',
        url: '',
        active: true,
      },
      {
        id: '2',
        type: 'viber',
        url: '',
        active: true,
      },
      {
        id: '3',
        type: 'whatsapp',
        url: '',
        active: true,
      },
      {
        id: '4',
        type: 'telegram',
        url: 'https://t.me/ctltg',
        active: true,
      },
      {
        id: '5',
        type: 'instagram',
        url: 'https://instagram.com/cattle_tag_qr_codes',
        active: true,
      },
      {
        id: '6',
        type: 'tiktok',
        url: '',
        active: true,
      },
      {
        id: '7',
        type: 'facebook',
        url: '',
        active: true,
      },
      {
        id: '8',
        type: 'youtube',
        url: '',
        active: true,
      },
    ],
    notifications: true,
    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, libero!',
    picture: '/public/images/64f33b5707b0bc3bc3065cc2_picture',
  };

  return (
    <Container>
      <Box className="bg-black px-2 py-8 sm:p-16 rounded flex flex-col items-center lg:grid gap-16 lg:grid-cols-12">
        <div className="lg:mr-auto place-self-center lg:col-span-7">
          <Typography variant="h1" fontWeight="bold" color="secondary">
            {t('Dynamic')}
            <span className="italic">
              {' '}
              {t('QR codes')}
              {' '}
            </span>
            {t('and')}
            {' '}
            <span className="italic">
              {' '}
              {t('links')}
              {' '}
            </span>
            {' '}
            {t('with analytics')}
          </Typography>
          <Typography variant="h6" color="secondary">
            {t('Promote your brand company with a'
            + ' dynamic QR on'
            + ' packaging, print, and screen, or with a link with analytics')}
          </Typography>
          <Link to="/auth" className="block mt-4">
            <Button
              color="secondary"
              endIcon={<SvgIcon><svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></SvgIcon>}
              variant="contained"
              size="large"
            >
              {t('Try for free')}
            </Button>
          </Link>
        </div>
        <div className="lg:mt-0 lg:col-span-5 flex justify-center items-center w-full relative">
          <QrCodeView className="qr-code-3d hidden lg:block -left-16 !absolute" url="https://cattlet.com/xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx" />
          <Box className="absolute -left-16 z-10 bottom-10">
            <SvgIcon sx={{ fontSize: '100px', width: '120px', height: '120px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                <path fill="#C21011" d="m138.058 296.404l-40.791-40.791l49.274-49.274c44.561-44.561 117.064-44.56 161.626 0l-40.791 40.791c-22.067-22.068-57.977-22.069-80.045 0l-49.273 49.274z" />
                <path fill="#000" d="M287.656 341.136c-30.527 0-59.227-11.888-80.812-33.475c-44.56-44.56-44.561-117.064 0-161.625l97.892-97.892c21.587-21.586 50.286-33.474 80.813-33.474s59.227 11.888 80.813 33.474c21.586 21.586 33.474 50.287 33.474 80.813s-11.888 59.227-33.474 80.813l-97.893 97.892c-21.585 21.586-50.284 33.474-80.813 33.474zm97.893-268.78c-15.118 0-29.332 5.888-40.023 16.578l-97.892 97.892c-22.068 22.069-22.068 57.977 0 80.045c10.69 10.69 24.904 16.578 40.022 16.578c15.119 0 29.332-5.887 40.022-16.577l97.893-97.893c10.69-10.69 16.578-24.904 16.578-40.022c0-15.119-5.888-29.332-16.578-40.023c-10.69-10.69-24.904-16.578-40.022-16.578z" />
                <path fill="#C21011" d="M343.932 70.414c-5.605 0-11.037-2.976-13.923-8.237c-4.214-7.681-1.403-17.324 6.278-21.538c31.379-17.211 69.118-17.305 98.494-.244c7.576 4.4 10.15 14.109 5.75 21.686c-4.401 7.576-14.108 10.15-21.686 5.75c-19.52-11.338-45.938-11.094-67.299.625a15.798 15.798 0 0 1-7.614 1.958zM129.461 499.277c-29.267 0-58.533-11.14-80.813-33.419c-44.56-44.561-44.56-117.066 0-161.627l70.465-70.464l40.79 40.791l-70.465 70.465c-22.068 22.068-22.068 57.977 0 80.045c11.033 11.033 25.53 16.55 40.022 16.551c14.496.002 28.987-5.516 40.023-16.551l97.892-97.893c10.69-10.69 16.578-24.904 16.578-40.022s-5.887-29.332-16.578-40.022l40.791-40.791c44.561 44.561 44.561 117.066 0 161.626l-97.892 97.893c-22.28 22.279-51.546 33.418-80.813 33.418z" />
              </svg>
            </SvgIcon>
          </Box>
          <Box className="relative" sx={{ maxWidth: '375px' }}>
            <UserCard alias="test" userBio={demoUser} />
          </Box>
        </div>
      </Box>
    </Container>
  );
}

export default Hero;
