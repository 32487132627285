import React from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import QrCodeView from 'src/components/QrCodeView';
import { TLink } from 'src/types/link';
import useActions from 'src/hooks/useActions';

function QRPrintPage() {
  const { count } = useParams();
  const draftLinks: Partial<TLink>[] = [];
  const { createLinks } = useActions();

  const renderQRs = (quantity: number) => {
    const qrCodes = [];

    for (let i = 0; i < quantity; i += 1) {
      const alias = uuidv4();

      draftLinks.push({
        alias,
        url: '',
        active: false,
      });

      qrCodes.push(
        <div className="break-after-page" key={i}>
          <QrCodeView url={`https://cattlet.com/${alias}`} />
        </div>,
      );
    }

    return qrCodes;
  };

  const handleClick = () => {
    createLinks(draftLinks);
  };

  return (
    <>
      <button type="button" className="print:hidden px-2 py-0.5" onClick={handleClick}>Create Tags</button>
      <section className="flex flex-wrap flex-col p-2">
        {renderQRs(Number(count))}
      </section>
    </>
  );
}

export default QRPrintPage;
