import { USERS_BASE_URL } from 'src/constants/constants';
import { fetch, fetchFile } from 'src/utils/fetch';
import { User } from 'src/types/user';

const getUserById = async (id: string) => {
  const response = await fetch.get(`${USERS_BASE_URL}${id}`);

  return response.data;
};

const update = async (userId: string, user: Partial<User>) => {
  const response = await fetch.patch(`${USERS_BASE_URL}${userId}`, user);

  return response.data;
};

const updatePicture = async (userId: string, data: any) => {
  const response = await fetchFile.post(`${USERS_BASE_URL}picture/${userId}`, data);

  return response.data;
};

const deletePicture = async (userId: string) => {
  const response = await fetch.delete(`${USERS_BASE_URL}picture/${userId}`);

  return response.data;
};

export default {
  deletePicture, update, updatePicture, getUserById,
};
