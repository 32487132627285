import React from 'react';
import { Container, Typography } from '@mui/material';

function TermsPage() {
  const website = 'cattlet.com';

  return (
    <Container>
      <article className="prose-sm lg:prose mx-auto bg-white p-8 rounded">
        <Typography variant="h1">Договір оферти доставлення, оплати і повернення</Typography>
        <p>
          Договір публічної оферти є публічним, тобто відповідно до статті 633 Цивільного кодексу
          України його умови однакові для всіх покупців незалежно від статусу (фізична особа,
          юридична особа, фізична особа – підприємець). При повній згоді з даним Договором, покупець
          приймає умови і порядок оформлення замовлення, оплати товару, доставки товару і за
          невиконання умов даного договору.
        </p>

        <p>
          Цей Договір є угодою між інтернет-магазином
          {website}
          {' '}
          (надалі по тексту – «Продавець») і будь-якою юридичною особою, фізичною
          особою-підприємцем або фізичною особою, користувачем послуг інтернет-магазину (надалі –
          «Покупець»), що включає всі суттєві умови організації купівлі-продажу дистанційним
          способом (тобто через інтернет-магазин).
        </p>
        <p>
          Умови даного Договору регулюють взаємовідносини Продавця і Покупця та визначаються Законом
          України «Про захист прав споживачів» № 1023-XII від 12 травня 1991 року, Правилами
          роздрібної торгівлі непродовольчими товарами, затвердженими наказом Міністерства економіки
          України № 104 від 19 квітня 2007 року.
        </p>
        <p>
          Ця угода носить характер публічної оферти, є еквівалентом «усної угоди» і відповідно до
          чинного законодавства України має належну юридичну силу.
        </p>

        <p>
          <strong>1. Загальні положення</strong>
        </p>
        <p>
          1.1. Цей договір є публічною офертою (відповідно до ст. 633, 641 і гл. 63 Цивільного
          кодексу України) і містить всі істотні умови організації купівлі-продажу дистанційним
          способом, тобто через інтернет-магазин.
        </p>
        <p>
          1.2. У відповідності до ст. 642 Цивільного Кодексу України повним і безумовним прийняттям
          умов публічного договору, тобто публічної оферти інтернет-магазину є факт оформлення
          замовлення натисканням на посилання «Купити» або «Оформити замовлення» в кошику та оплати
          замовлення на умовах цієї угоди.
        </p>
        <p>
          1.3. Укладаючи Договір, Покупець підтверджує, що цілком і повністю ознайомлений та згоден
          з його умовами, а також, у разі, якщо Покупець є фізичною особою, дає дозвіл на обробку
          своїх персональних даних з метою можливості виконання умов цього Договору, можливості
          проведення взаєморозрахунків, а також для отримання рахунків, актів та інших документів.
          Дозвіл на обробку персональних даних діє протягом усього строку дії Договору. Крім цього,
          укладанням цього Договору, Покупець підтверджує, що йому повідомлено (без додаткового
          повідомлення) про права, встановлені Законом України «Про захист персональних даних», про
          цілі збору даних. Обсяг прав Покупця, як суб’єкта персональних даних відповідно до Закону
          України «Про захист персональних даних», йому відомий і зрозумілий.
        </p>

        <p>
          <strong>2. Визначення термінів</strong>
        </p>
        <p>
          2.1. Під поняттям «інтернет-магазин» розуміється відповідний програмно-функціональний
          комплекс, розміщений на сайті
          {website}
          , який дозволяє ознайомитись з Товаром, його зовнішнім виглядом,
          характеристиками, ціною, умовами оплати, умовами та строками доставки, гарантійними
          зобов’язаннями тощо, зробити відповідне замовлення за допомогою мережі Інтернет, що
          виключає можливість безпосереднього ознайомлення Покупця з Товаром – дистанційним засобом
          продажу товару.
        </p>
        <p>
          2.2. Під поняттям «товар» розуміється перелік найменувань асортименту, представлений в
          інтернет-магазині, щодо яких вказана ціна, назва та опис і які доступні до придбання.
        </p>
        <p>
          2.3. Під поняттям «замовлення» розуміється оформлена і розміщена заявка Покупця,
          адресована інтернет-магазину, на пошук, комплектацію та резервування товарів.
        </p>
        <p>
          2.4. Під поняттям «оформити замовлення» розуміється рішення Покупця придбати товар,
          оформлене в інтернет-магазині.
        </p>
        <p>
          2.5. Під поняттям «кошик» розуміється частина інтернет-магазину, що відповідає за
          відслідковування та відображення в реальному часі вибраних Покупцем товарів для придбання
          із зазначенням загальної вартості на них.
        </p>
        <p>
          2.6. Під поняттям «персональні дані» розуміється будь-яка інформація, яка прямо чи
          опосередковано відноситься до певної особи чи до особи, яка визначається.
        </p>

        <p>
          <strong>3. Предмет договору</strong>
        </p>
        <p>
          3.1. Продавець зобов’язується на умовах та в порядку, визначених цим Договором, передати у
          власність Покупцеві товар, а Покупець зобов’язується оплатити і прийняти товар на умовах
          Цього договору.
        </p>
        <p>3.2. Цей договір регулює купівлю-продаж в інтернет-магазині, в тому числі:</p>
        <p>а) добровільний вибір Покупцем товарів в інтернет-магазині за каталогом;</p>
        <p>б) самостійне оформлення Покупцем замовлення на сайті в інтернет-магазині;</p>
        <p>в) оплата Покупцем замовлення, оформленого в інтернет-магазині;</p>
        <p>г) виконання і передача замовлення Покупцеві у власність на умовах цього Договору.</p>
        <p>
          3.3. Продавець гарантує, що Товар не переданий під заставу, не знаходиться під арештом та
          на нього не розповсюджуються права третіх осіб, а також гарантує, що має право на
          реалізацію Товару без будь-яких обмежень, відповідно до вимог чинного законодавства
          України, та зобов’язується нести відповідальність в разі порушення прав Покупця в процесі
          виконання Договору та реалізації Товару.
        </p>

        <p>
          <strong>4. Порядок оформлення замовлення</strong>
        </p>
        <p>4.1. Покупець самостійно оформляє замовлення в інтернет-магазині.</p>
        <p>
          4.2. Оформлення Покупцем замовлення і подальша передача його до виконання означає достатнє
          й повне ознайомлення Покупця з технічними характеристиками товару, його функціональним
          можливостями, з інформацією про терміни поставки та умови гарантійного обслуговування.
        </p>

        <p>
          <strong>5. Ціна та порядок розрахунків</strong>
        </p>
        <p>
          5.1. Повна вартість замовлення складається з вартості товару (зазначеної в каталозі) і
          вартості доставки.
        </p>
        <p>5.2. Ціна товару та замовлення встановлюється в національній валюті України.</p>
        <p>
          5.3. Оплата товару здійснюється Покупцем згідно з умовами та тарифами, вказаними на
          сторінці інтернет-магазину https://
          {website}
          /payment.
        </p>
        <p>
          5.4. Покупець оплачує замовлення будь-яким способом, обраним в інтернет-магазині протягом
          2-х робочих днів з моменту оформлення замовлення. При розрахунку на сайті Продавця
          Покупець сплачує лише ціну обраного товару без додаткових комісій за розрахунково касове
          обслуговування.
        </p>

        <p>
          <strong>6. Доставка замовлення</strong>
        </p>
        <p>
          6.1. Загальний термін доставки товару складається з терміну обробки замовлення і терміну
          доставки.
        </p>
        <p>
          6.2. Доставка і повернення товару виконується силами Продавця або транспортної компанії
          (перевізником) за рахунок Покупця.
        </p>
        <p>
          6.3. Вартість доставки залежить від діючих тарифів транспортної компанії (перевізника).
        </p>
        <p>
          6.4. При замовленні оптових партій, терміни і методи доставки узгоджуються окремо в
          кожному конкретному випадку.
        </p>
        <p>
          6.5. Продавець не несе відповідальності за термін доставки замовлення, тому що вони
          залежать від дій третіх осіб (перевізників).
        </p>
        <p>
          6.6. Вартість доставки замовлення оплачується Покупцем самостійно при отриманні
          замовлення.
        </p>

        <p>
          <strong>7. Порядок повернення товару</strong>
        </p>
        <p>
          7.1. Покупець має право обміняти Товар належної якості на аналогічний у продавця в якого
          він був придбаний, якщо товар не задовольнив його за формою, габаритами, фасоном,
          кольором, розміром або з інших причин не може бути ним використаний за призначенням за
          наступними умовами:
        </p>
        <p>
          7.1.1. товар для обміну Продавцю надано протягом не більше чотирнадцяти днів, не рахуючи
          дня купівлі;
        </p>
        <p>
          7.1.2. товар може бути замінений якщо він жодного разу не був в експлуатації, не містить
          сліди використання і якщо збережено його товарний вигляд, споживчі властивості, пломби,
          ярлики, плівки, не порушено цілісність пакування як самого товару, так і комплектуючих до
          нього;
        </p>
        <p>7.1.3. товар не містить подряпин, сколів, потертості, повністю справний;</p>
        <p>7.1.4. збережена повна комплектність проданого товару;</p>
        <p>
          7.1.5. товар може бути замінений при пред’явленні Покупцем розрахункового документа,
          виданого Покупцю разом з проданим Товаром.
        </p>
        <p>
          7.2. Вимоги п.7.1. не поширюються на товари, які у відповідності до Додатку №3 до
          Постанови Кабінету Мінстрів України від 19.03.94 №172 «Про реалізацію окремих положень
          Закону України «Про захист прав споживачів», віднесені до Переліку товарів належної
          якості, що не підлягають обміну (поверненню).
        </p>
        <p>
          7.3. У разі, якщо Товар не відповідає умовам визначеним пп.7.1.1-7.1.5 п.7.1 цього
          Договору, Продавець має право відмовити в обміні Товару.
        </p>
        <p>
          7.4. Транспортні витрати на доставку Товару при обміні за п. 7.1. покладаються на Покупця.
        </p>
        <p>
          7.5. Якщо на момент обміну аналогічного товару немає у продажу, Покупець має право або
          придбати будь-які інші товари з наявного асортименту з відповідним перерахуванням
          вартості, або розірвати договір та одержати назад гроші у розмірі вартості повернутого
          товару, або здійснити обмін товару на аналогічний при першому ж надходженні відповідного
          товару в продаж.
        </p>
        <p>
          7.6. Під товаром неналежної якості розуміється товар, який не може забезпечити виконання
          своїх функціональних властивостей. Відмінність елементів дизайну, кольору або оформлення
          від заявлених в описі в інтернет-магазині не є ознакою неналежної якості Товару та/або
          неможливості його використання за призначенням.
        </p>
        <p>
          7.7. Після отримання замовлення претензії до зовнішніх дефектів товару, його кількості,
          комплектності та товарного вигляду не приймаються.
        </p>
        <p>
          7.8. Якщо Покупцеві був переданий товар неналежної якості, Покупець має право відповідно
          до Закону України «Про захист прав споживачів» вимагати повернення сплачених грошових
          коштів.
        </p>
        <p>
          7.9. Продавець не відповідає за недоліки Товару, які виникли після передачі його Покупцю
          внаслідок порушення Покупцем правил користування або зберігання Товару, дій третіх осіб
          або обставин непереборної сили.
        </p>

        <p>
          <strong>8. Права та обов’язки сторін</strong>
        </p>
        <p>8.1. Покупець зобов’язаний:</p>
        <p>8.1.1. ознайомитись з інформацією про товар, яка розміщена на сайті Продавця;</p>
        <p>8.1.2. самостійно оформити замовлення в інтернет-магазині;</p>
        <p>
          8.1.3. своєчасно оплатити та отримати замовлення у Перевізника на умовах цього договору;
        </p>
        <p>
          8.1.4. при отриманні товару у перевізника впевнитися у його цілісності шляхом огляду
          вмісту упаковки, після чого відкрити її та безпосередньо пересвідчитися у належному
          зовнішньому стані Товару, відсутності механічних ушкоджень та повноти його комплектності.
          У випадку виявлення пошкоджень та неповної комплектації – зафіксувати їх в акті, який
          разом із Покупцем повинен підписати співробітник перевізника.
        </p>
        <p>8.2. Продавець зобов’язаний:</p>
        <p>8.2.1. надавати правдиву інформацію щодо Товарів, умов доставки та цін на них;</p>
        <p>
          8.2.2. передати Покупцю товар у відповідності з обраним зразком, розміщеним в
          інтернет-магазині, оформленим замовленням та умовами цього договору;
        </p>
        <p>8.2.3. дотримувати умов цього договору</p>
        <p>8.3. Покупець має право:</p>
        <p>
          8.3.1. до передачі Товару відмовитися від виконання Договору за умови відшкодування
          Продавцеві транспортних витрат, понесених Продавцем у зв’язку зі здійсненням дій по
          виконанню даного Договору;
        </p>
        <p>8.3.2. вимагати від Продавця дотримання умов цього договору.</p>
        <p>8.4. Права продавця:</p>
        <p>
          8.4.1. в односторонньому порядку призупинити надання послуг за цим Договором у випадку
          порушення Покупцем умов цього Договору;
        </p>
        <p>8.4.2. вимагати від Покупця оплати замовлення;</p>
        <p>8.4.3. вимагати від Покупця дотримання умов цього договору.</p>

        <p>
          <strong>9. Відповідальність сторін</strong>
        </p>
        <p>
          9.1. Сторони відповідають за невиконання або неналежне виконання умов цього Договору в
          порядку, передбаченому цим Договором і чинним законодавством України.
        </p>
        <p>
          9.2. Продавець не несе відповідальності за неналежне, невчасне виконання Замовлення та
          своїх зобов’язань за цим Договором в разі надання Покупцем недостовірної, неправдивої та
          некоректної інформації.
        </p>
        <p>
          9.3. У випадку обставин непереборної сили, сторони звільняються від виконання умов цього
          Договору. Під обставинами непереборної сили для цілей цього Договору розуміються події, що
          мають надзвичайний, невідворотний непередбачуваний характер, які виключають або об’єктивно
          перешкоджають виконанню цього Договору, настання яких Сторони не могли передбачити й
          запобігти розумними мірам.
        </p>
        <p>
          9.4. Сторони приймуть усі міри до розгляду спорів та розбіжностей, які можуть виникнути
          при виконанні зобов’язань за Договором або у зв’язку із цим, шляхом переговорів.
        </p>

        <p>
          <strong>10. Інші умови</strong>
        </p>
        <p>
          10.1. Цей договір набирає чинності з дня оформлення замовлення або реєстрації в
          інтернет-магазині
          {' '}
          {website}
          {' '}
          і діє до виконання всіх умов договору.
        </p>
        <p>
          10.2. Інтернет-магазин залишає за собою право в односторонньому порядку вносити зміни в
          цей Договір з попередньою публікацією його на сайті
          {website}
          .
        </p>
        <p>
          10.3. Інтернет-магазин створений для організації дистанційного способу продажу товарів
          через Інтернет.
        </p>
        <p>
          10.4. Оплата Покупцем оформленого в Інтернет-магазині замовлення означає повну згоду
          Покупця з умовами Договору купівлі-продажу (публічної оферти Інтернет-магазину) і є датою
          укладення Договору купівлі-продажу між Продавцем і Покупцем.
        </p>
        <p>
          10.5. Інформація, що надається Покупцем є конфіденційною. Інтернет-магазин використовує
          інформацію про Покупця виключно в цілях функціонування Інтернет-магазину (відправлення
          повідомлення Покупцеві про виконання замовлення, надсилання рекламних повідомлень тощо).
        </p>
        <p>
          10.6. Покупець дає Продавцю право здійснювати обробку його персональних даних, у тому
          числі: поміщати персональні дані в бази даних Покупця (без додаткового повідомлення про
          це), здійснювати довічне зберігання даних, їх накопичення, оновлення, зміну (у міру
          необхідності). Покупець бере на себе зобов’язання забезпечити захист даних від
          несанкціонованого доступу третіх осіб, не поширювати і не передавати дані будь-якій третій
          стороні (крім передачі даних пов’язаним особам, комерційним партнерам, особам,
          уповноваженим Продавцем на здійснення безпосередньої обробки даних для зазначених цілей, а
          також на обов’язковий запит компетентного державного органу).
        </p>
      </article>
    </Container>
  );
}

export default TermsPage;
