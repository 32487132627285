import React from 'react';
import { Container, Typography } from '@mui/material';

function PrivacyPage() {
  const owner = 'Cattle Tag';
  const website = 'https://cattlet.com/';

  return (
    <Container>
      <article className="prose-sm lg:prose mx-auto bg-white p-8 rounded">
        <Typography variant="h1">
          Політика конфіденційності та захисту персональних даних
        </Typography>

        <p>
          <strong>Загальні положення</strong>
        </p>
        <p>
          Ця Політика конфіденційності описує, як
          {website}
          {' '}
          (далі – «Сайт», «ми») збирає, використовує, передає, зберігає персональні дані,
          які ми автоматично отримуємо, коли ви використовуєте наші веб-ресурси, або які можуть бути
          безпосередньо надані нам вами за допомогою Сайту.
        </p>

        <p>
          <strong>Збір та використання персональних даних</strong>
        </p>
        <p>
          Володільцем та розпорядником персональних даних користувачів Сайту є
          {owner}
          .
        </p>
        <p>
          При використанні користувачем сервісів Сайту
          {owner}
          {' '}
          здійснюється обробка даних користувача, а саме:
        </p>
        <ul>
          <li>
            даних, що надаються користувачем як при заповненні реєстраційних форм, так і в процесі
            користування сервісами;
          </li>
          <li>файли cookie;</li>
          <li>ір-адреси;</li>
          <li>параметри і налаштувань інтернет-браузерів (User-agent).</li>
        </ul>
        <p>
          {owner}
          {' '}
          збирає тільки ті персональні дані (наприклад, Ваше ім&apos;я і прізвище, логін і пароль
          доступу, адреса електронної пошти, номер контактного телефону, дата народження, стать і
          т.д.), які свідомо і добровільно надані Вами як суб&apos;єктом персональних даних в цілях
          використання сервісів Сайту, що відповідно до вимог законодавства є згодою суб&apos;єкта
          персональних даних на обробку своїх персональних даних відповідно до сформульованої в цій
          Політиці мети їх обробки.
        </p>
        <p>
          При відвідуванні Сайту можуть фіксуватись всі входи до системи. Інші відомості по трафіку
          користувача не обробляються і не зберігаються.
        </p>
        <p>
          Звертаємо Вашу увагу: ми обмежуємся збором мінімального обсягу інформації, необхідного
          виключно для виконання запиту суб&apos;єкта персональних даних. У будь-якому випадку, коли
          запитується необов&apos;язкова до надання інформація, користувача буде повідомлено в
          момент збору такої інформації.
        </p>
        <p>
          Ми не збираємо будь-яку інформацію, до обробки якої, законодавством встановлені певні
          вимоги, як-то інформацію про расове або етнічне походження, політичні, релігійні або
          світоглядні переконання, членство в політичних партіях та професійних спілках, засудження
          до кримінального покарання в скоєнні злочину або засудження до кримінального покарання, а
          також даних, що стосуються здоров&apos;я, статевого життя, біометричних або генетичних
          даних (відповідно до статті 7 Закону України «Про захист персональних даних»).
        </p>
        <p>
          Ми можемо збирати дані про статистику відвідування Сайту. Відомості можуть містити
          інформацію про з&apos;єднання, трафік, браузер користувача, а також про дату, час,
          тривалість роботи в мережі Інтернет та знаходження на Сайті.
        </p>

        <p>
          <strong>Обробка персональних даних</strong>
        </p>
        <p>
          Обробка і зберігання наданих персональних даних здійснюється в дата-центрах, де
          розміщується обладнання, що забезпечує функціонування сервісів Сайту. Надані персональні
          дані обробляються і можуть зберігатись в Базі персональних даних чи окремій таблиці Бази
          даних Сайту.
        </p>

        <p>
          <strong>Цілі використання персональних даних</strong>
        </p>
        <p>
          Ваші персональні дані використовуються в цілях забезпечення надання Інтернет-сервісів
          Сайту, обміну інформацією/новинами, відносин у сфері реклами та комунікації відповідно та
          на виконання законів України, у тому числі, але не виключно: «Про захист персональних
          даних», «Про ратифікацію Конвенції про захист осіб у зв&apos;язку з автоматизованою
          обробкою персональних даних та Додаткового протоколу до Конвенції про захист осіб у
          зв&apos;язку з автоматизованою обробкою персональних даних стосовно органів нагляду та
          транскордонних потоків даних», «Про інформацію», «Про рекламу», «Про телекомунікації»,
          «Про захист інформації в інформаційно-телекомунікаційних системах», а також відповідно
          до Правил використання сайту та інших актів, що регулюють діяльність
          {owner}
          .
        </p>

        <p>
          <strong>Термін зберігання персональних даних</strong>
        </p>
        <p>
          Персональні дані зберігаються на термін не більше, ніж це необхідно відповідно до мети їх
          обробки.
        </p>

        <p>
          <strong>Використання файлів cookie</strong>
        </p>
        <p>
          Cookie - це текстовий файл або файли, що містять невеликий обсяг інформації, які
          надсилаються веб-браузеру і зберігаються на пристрої користувача. До таких пристроїв можна
          віднести комп&apos;ютер, мобільний телефон або інший пристрій, за допомогою якого
          користувач відвідує Сайт.
        </p>
        <p>
          Файли cookie можуть бути вічними (вони називаються постійними файлами cookie) і
          зберігатися в комп&apos;ютері, поки користувач їх не видалить або тимчасовими (такі файли
          cookie називаються сесійними), тобто зберігаються тільки до закриття браузера. Крім того,
          файли cookie поділяються на основні (вони встановлюються безпосередньо відвідуваним
          Сайтом) і сторонні (встановлюються іншими веб-сайтами).
        </p>
        <i>Важливо:</i>
        <ul>
          <li>при повторному відвідуванні користувачем Сайту, дані файлів cookie оновлюються;</li>
          <li>
            у більшості випадків, веб-браузер за замовчуванням допускає автоматичне зберігання
            файлів cookie на пристрої користувача;
          </li>
          <li>
            відключення файлів cookie може призвести до обмеження доступу до опублікованих
            матеріалів та/або неповноцінного функціонування сервісів Сайту.
          </li>
        </ul>
        <i>
          Відповідно до класифікації Міжнародної торгової палати (International Chamber of
          Commerce),
          {owner}
          {' '}
          використовує такі категорії файлів cookie:
        </i>
        <p>
          Строго необхідні файли cookie - потрібні для пересування користувачем по веб-сторінці і
          при використанні певних сервісів, наприклад, для доступу до захищених сторінок, реєстрації
          та авторизації, здійснення пошуку по Сайту, тощо.
        </p>
        <p>
          Експлуатаційні файли cookie - агрегують інформацію про те, як використовується Сайт. Ці
          дані зберігаються на пристрої користувача між сеансами веб-браузера.
        </p>
        <p>
          Прикладами таких даних можуть бути наступні метрики: час перебування на Сайті, найбільш
          часто відвідувані сторінки, розуміння які саме розділи і сервіси Сайту були найбільш
          цікаві для користувача, тощо.
        </p>
        <p>
          Вся інформація, зібрана за допомогою експлуатаційних файлів cookie, призначена для
          статистичних та аналітичних задач.
        </p>
        <p>
          Функціональні файли cookie - використовуються для збереження параметрів або конфігурацій,
          які зберігаються на пристрої користувача між сеансами веб-браузера.
        </p>
        <p>
          Прикладами таких даних можуть бути наступні метрики: ім&apos;я користувача, фото в
          профілі, інформація по залишених коментарях, мовна версія Сайту, місцезнаходження,
          відомості про те, чи надавалася користувачеві будь-яка інформація або обрані переваги
          раніше, а також інші параметри налаштування Сайту.
        </p>
        <p>
          Цільові файли cookie - використовуються для надання контенту, який може зацікавити
          користувача. Ці дані зберігаються на пристрої користувача між сеансами веб-браузера.
          Прикладами таких даних можуть бути наступні метрики: відстеження рекомендованого
          текстового, графічного, аудіо та відеоматеріалу, щоб уникнути повторного показу,
          інформація про відвідування користувачем інших ресурсів при переходах, а також інші
          параметри налаштування Сайту.
        </p>
        <i>Cookie-файли сторонніх сервісів і сервісів аналітики:</i>

        <p>
          Для оперативної доставки, більш якісного відображення і детального аналізу контенту на
          Сайті,
          {owner}
          {' '}
          може користуватися послугами, які є власністю інших сторонніх компаній, таких як
          Facebook, Twitter, Instagram, Alphabet Inc., Gemius та інші.
        </p>
        <p>
          Наведені як приклад компанії можуть використовувати файли cookie на пристрої користувача,
          під час роботи на Сайті.
        </p>
        <p>
          Слід звернути увагу, що Сайт не може вплинути на роботу файлів cookie, які
          використовуються цими сервісами. Всі необхідні відомості про їх використання можна
          дізнатися, відвідавши відповідний ресурс.
        </p>
        <i>Важливо:</i>
        <ul>
          <li>
            варто нагадати, що повноцінна робота з Сайтом доступна тільки при використанні файлів
            cookie;
          </li>
        </ul>
        <i>
          Інформація про користувачів отримана за допомогою файлів cookie не продається і не
          поширюється у відкритому доступі, а також є власністю організації, якій належить ресурс.
        </i>

        <p>
          <strong>Взаємодія Сайту з іншими ресурсами</strong>
        </p>
        <p>
          При використанні користувачем сервісів, на сторінках Сайту можуть бути присутніми коди
          інших інтернет ресурсів і третіх осіб, в результаті чого такі інтернет ресурси і треті
          особи отримують Ваші дані. Отже, ці інтернет-ресурси можуть отримувати і обробляти
          інформацію, про те, що Ви відвідали ці сторінки, а також іншу інформацію, яку передає
          браузер користувача. Такими інтернет-ресурсами можуть бути:
        </p>
        <ul>
          <li>
            системи банеропоказів (наприклад, DoubleClick for Publishers, Admixer, AdRiver та ін.);
          </li>
          <li>соціальні плагіни мереж (наприклад, Facebook, Twitter, тощо).</li>
        </ul>
        <p>Дані, отримані від зазначених сервісів не зберігаються і не обробляються.</p>
        <p>
          Відповідно, якщо користувач в силу будь-яких причин не бажає, щоб зазначені сервіси
          отримували доступ до його персональних даних, користувач може за власним бажанням вийти зі
          свого аккаунта чи профіля, очистити файли cookie (через свій браузер).
        </p>

        <p>
          <strong>Надання інформації третім особам</strong>
        </p>
        <p>
          {owner}
          {' '}
          не здійснює передачу персональних даних третім особам, крім випадків, коли така
          передача є вимогою законодавства, на прохання суб&apos;єкта персональних даних або в інших
          випадках, викладених в цій Політиці.
        </p>
        <p>
          На Сайті можуть бути посилання на інші веб-сайти (виключно в інформаційних цілях). При
          переході по посиланню на інші веб-сайти дія цієї Політики на такі сайти поширюватися не
          буде. У зв&apos;язку з чим, ми рекомендуємо переглядати політику в сфері конфіденційності
          і персональних даних кожного веб-сайту перед тим, як передавати будь-які персональні дані,
          за якими Вас можуть ідентифікувати.
        </p>

        <p>
          <strong>Захист персональних даних</strong>
        </p>
        <p>
          Поширення персональних даних без згоди суб&apos;єкта персональних даних або уповноваженої
          ним особи дозволяється у випадках, визначених законом, і лише (якщо це необхідно) в
          інтересах національної безпеки, економічного добробуту та прав людини.
        </p>

        <p>
          <strong>Зміна Політики</strong>
        </p>
        <p>
          Ми можемо вносити зміни у цю Політику конфіденційності, у тому числі, при зміні вимог
          законодавства. Просимо вас час від часу ознайомлюватись із цією Політикою.
        </p>
      </article>
    </Container>
  );
}

export default PrivacyPage;
