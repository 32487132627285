import React from 'react';
import QRCode from 'qrcode.react';
import logo from 'src/assets/logo.svg';

interface QrCodeViewProps {
  url: string;
  size?: number;
  className?: string;
}

function QrCodeView({ url, size, className }: QrCodeViewProps) {
  const mm = 3.7795275591;
  const aspectRatioLogo = 0.723756906077348;
  const logoSize = 9 * mm;
  const height = 56 * mm;
  const width = 46 * mm;
  const bgColor = '#000';
  const qrColor = '#fff1f1';

  return (
    <div
      className={`${className} inline-block m-1 overflow-hidden relative bg-black text-center rounded-xl`}
      style={{ border: `${mm}px solid`, padding: `${2 * mm}px` }}
    >
      <div style={{ width: `${width}px`, height: `${height}px` }} className="flex flex-col">
        <span className="text-white flex items-center justify-center font-nunito font-bold text-sm flex-1 pb-1.5 text-center">тут ваш текст</span>
        <QRCode
          value={url}
          size={size}
          renderAs="svg"
          level="H"
          fgColor={qrColor}
          bgColor={bgColor}
          imageSettings={{
            src: logo,
            height: logoSize / aspectRatioLogo,
            width: logoSize,
            excavate: true,
          }}
        />
      </div>
    </div>
  );
}

QrCodeView.defaultProps = {
  size: 46 * 3.7795275591,
  className: '',
};

export default QrCodeView;
