import toast from 'react-hot-toast';

export const urlBase64ToUint8Array = (base64String: any) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 1; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

export const requestNotificationPermission = async (cb: Function, alias: string) => {
  try {
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array('BN7w_rGiS5QZaYhwmgOiPtNp3JtCbyH8V5gum68-bXO8juus7H9v4KJEjQXumZYG-2OgyuBh_2PoUJWUF0-CTQc'),
      });

      cb({ subscription, alias });
    }
  } catch (error) {
    toast.error(`Помилка отримання дозволу на сповіщення: ${error}`);
  }
};

export const iOS = () => [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod',
].includes(navigator.platform)
        || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

export const formatNumber = (number: number) => {
  const billion = 1000000000;
  const million = 1000000;
  const thousand = 1000;

  if (number >= billion) {
    return `${(number / billion).toFixed(1)}B`;
  } if (number >= million) {
    return `${(number / million).toFixed(1)}M`;
  } if (number >= thousand) {
    return `${(number / thousand).toFixed(1)}K`;
  }

  return number.toString();
};

export const timeAgo = (date: Date) => {
  const currentDate = new Date();
  const timestamp = new Date(date).getTime();
  const currentDatestamp = currentDate.getTime();
  const seconds = Math.floor((currentDatestamp - timestamp) / 1000);

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  } if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes + (minutes === 1 ? ' minute' : ' minutes')} ago`;
  } if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return `${hours + (hours === 1 ? ' hour' : ' hours')} ago`;
  }
  const days = Math.floor(seconds / 86400);
  return `${days + (days === 1 ? ' day' : ' days')} ago`;
};

function isValidUrl(url: string): boolean {
  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

  return urlRegex.test(url);
}

export const formatUserPicture = (src: string) => (isValidUrl(src) ? src : `${process.env.REACT_APP_API_BASE_URL}${src.replace('/', '')}`);
