import {
  Container, Paper, SvgIcon, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TelegramIcon } from 'src/assets/icons/telegram.svg';
import { ReactComponent as InstagramIcon } from 'src/assets/icons/instagram.svg';

function ContactsPage() {
  const { t } = useTranslation();

  return (
    <Container>
      <Paper className="prose-sm lg:prose mx-auto p-8">
        <Typography variant="h1">{t('Contact information')}</Typography>
        <Typography>{t('To contact us and receive additional information, please use the following information:')}</Typography>
        <div className="flex gap-4 mt-2">
          <a href="//www.instagram.com/cattle_tag_qr_codes/" target="_blank" rel="noreferrer">
            <span className="sr-only">Instagram</span>
            <SvgIcon fontSize="large">
              <InstagramIcon />
            </SvgIcon>
          </a>
          <a href="https://t.me/ctltg" target="_blank" rel="noreferrer">
            <span className="sr-only">Telegram</span>
            <SvgIcon fontSize="large">
              <TelegramIcon />
            </SvgIcon>
          </a>
        </div>
      </Paper>
    </Container>
  );
}

export default ContactsPage;
