import React, { FormEvent, useState } from 'react';
import useActions from 'src/hooks/useActions';
import { User } from 'src/types/user';
import MuiPhone from 'src/components/MuiPhone';
import {
  Button,
  List,
  ListItem,
  Switch,
  TextField,
} from '@mui/material';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { useTranslation } from 'react-i18next';
import AvatarUpload from 'src/components/AvatarUpload';
import SocialLinksForm from 'src/pages/Account/components/SocialLinksForm';
import NotificationsFormItem from 'src/pages/Account/components/NotificationsFormItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

interface UserFormProps {
  user: User
}

function UserForm({ user }: UserFormProps) {
  const [form, setForm] = useState(user);
  const {
    notifications,
    link,
    cardNumber,
    phone,
    name,
    description,
  } = form;
  const { update } = useActions();
  const { t } = useTranslation();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const settingName = e.target.name.replace('_checkbox', '');

    setForm({
      ...form,
      [e.target.name.replace('_checkbox', '')]: {
        ...form[settingName as keyof User],
        active: e.target.checked,
      },
    });
  };

  const handleSettingChange = (field: keyof User, key: string, value: any) => {
    setForm({
      ...form,
      [field]: {
        ...form[field],
        [key]: value,
      },
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { id } = user;

    update({
      userId: id,
      user: {
        ...form,
      },
    });
  };

  return (
    <form className="grid gap-4" onSubmit={handleSubmit}>
      <List disablePadding>
        <ListItem>
          <AvatarUpload />
        </ListItem>
        <ListItem>
          <TextField
            label={t('Name')}
            fullWidth
            variant="outlined"
            size="small"
            value={name}
            name="name"
            onChange={handleInputChange}
            autoComplete="name"
          />
        </ListItem>
        <ListItem>
          <TextField
            label={t('Description')}
            fullWidth
            variant="outlined"
            size="small"
            value={description}
            name="description"
            onChange={handleInputChange}
          />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <NotificationsActiveOutlinedIcon fontSize="large" color="primary" />
          </ListItemIcon>
          <ListItemText id="switch-list-label-notifications" primary={t('Receive notifications')} />
          <Switch
            edge="end"
            name="notifications_checkbox"
            checked={notifications?.active}
            onChange={onSwitchChange}
            inputProps={{ 'aria-labelledby': 'switch-list-label-notifications' }}
          />
        </ListItem>

        <NotificationsFormItem formData={form} />

        <ListItem>
          <ListItemIcon>
            <LinkOutlinedIcon fontSize="large" color="primary" />
          </ListItemIcon>
          <ListItemText id="switch-list-label-link" primary={t('Link')} />
          <Switch
            edge="end"
            name="link_checkbox"
            checked={link?.active}
            onChange={onSwitchChange}
            inputProps={{ 'aria-labelledby': 'switch-list-label-link' }}
          />
        </ListItem>

        {link?.active && (
          <>
            <ListItem>
              <TextField
                size="small"
                fullWidth
                placeholder="https://yourlink.domain"
                variant="outlined"
                value={link.value}
                name="link"
                onChange={(e: any) => handleSettingChange('link', 'value', e.target.value)}
                autoComplete="url"
              />
            </ListItem>
            <ListItem>
              <TextField
                size="small"
                fullWidth
                label={t('Description')}
                placeholder={t('My Website')}
                variant="outlined"
                value={link.label}
                name="label"
                onChange={(e: any) => handleSettingChange('link', 'label', e.target.value)}
                autoComplete="off"
              />
            </ListItem>
          </>
        )}

        <ListItem>
          <ListItemIcon>
            <CreditCardOutlinedIcon fontSize="large" color="primary" />
          </ListItemIcon>
          <ListItemText id="switch-list-label-card" primary={t('Card Number')} />
          <Switch
            edge="end"
            name="cardNumber_checkbox"
            checked={cardNumber?.active}
            onChange={onSwitchChange}
            inputProps={{ 'aria-labelledby': 'switch-list-label-card' }}
          />
        </ListItem>

        {cardNumber?.active && (
          <ListItem>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              placeholder="9999999999999999"
              value={cardNumber.value}
              name="cardNumber"
              onChange={(e: any) => handleSettingChange('cardNumber', 'value', e.target.value)}
              autoComplete="cc-number"
              inputMode="numeric"
            />
          </ListItem>
        )}

        <ListItem>
          <ListItemIcon>
            <CallOutlinedIcon fontSize="large" color="primary" />
          </ListItemIcon>
          <ListItemText id="switch-list-label-phone" primary={t('Phone Number')} />
          <Switch
            edge="end"
            name="phone_checkbox"
            checked={phone?.active}
            onChange={onSwitchChange}
            inputProps={{ 'aria-labelledby': 'switch-list-label-phone' }}
          />
        </ListItem>

        {phone?.active && (
          <ListItem>
            <MuiPhone
              value={phone.value}
              id="phone"
              name="phone"
              onChange={(value: string) => handleSettingChange('phone', 'value', value)}
            />
          </ListItem>
        )}

        <SocialLinksForm form={form} setForm={setForm} />
      </List>

      <Button type="submit" size="large" fullWidth variant="contained">{t('Save')}</Button>
    </form>
  );
}

export default UserForm;
