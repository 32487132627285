import { Alert, Button, ListItem } from '@mui/material';
import { iOS, requestNotificationPermission } from 'src/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'src/types/user';
import { useAppSelector } from 'src/hooks';
import useActions from 'src/hooks/useActions';

function NotificationsFormItem({ formData }: { formData: Partial<User> }) {
  const { user } = useAppSelector((state) => state.user);
  const [isNotificationPermissionGranted, setIsNotificationPermissionGranted] = useState(false);
  const { subscribePush } = useActions();
  const { t } = useTranslation();

  useEffect(() => {
    if ('Notification' in window) {
      setIsNotificationPermissionGranted(Notification.permission === 'granted');
    }
  }, []);

  if (isNotificationPermissionGranted || !formData.notifications?.active) {
    return null;
  }

  return (
    <ListItem>
      <div className="grid gap-2 w-full">
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            let alias = '';

            if (user && user.links && user.links[0].alias) {
              alias = user.links[0].alias;
            }

            requestNotificationPermission(subscribePush, alias).then(() => {
              setIsNotificationPermissionGranted('Notification' in window);
            });
          }}
        >
          {t('Enable notifications')}
        </Button>
        <Alert
          severity="warning"
        >
          {t('Attention: To receive notifications, grant permission to receive them.')}
        </Alert>
        {iOS()
        && <Alert severity="warning">Для iOS Вимагає додавання веб-сайту на початковий екран.</Alert>}
      </div>
    </ListItem>
  );
}

export default NotificationsFormItem;
