import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { Link, useLocation } from 'react-router-dom';
import logo from 'src/assets/logo.svg';
import * as React from 'react';
import {
  CSSObject, styled, Theme, useTheme,
} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'src/components/LanguageSwitcher';

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export function DrawerComponent({ open, setOpen, width }: any) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const navItems = [
    { title: t('My Page'), to: '/account', icon: <DashboardCustomizeOutlinedIcon color="primary" /> },
    { title: t('My Links'), to: '/links', icon: <LinkOutlinedIcon color="primary" /> },
  ];

  const footerNavItems = [
    { title: t('Contacts'), to: '/contacts', icon: <ContactsOutlinedIcon color="primary" /> },
    { title: t('Terms'), to: '/terms-and-conditions', icon: <DescriptionOutlinedIcon color="primary" /> },
    { title: t('Privacy'), to: '/privacy-policy', icon: <PrivacyTipOutlinedIcon color="primary" /> },
  ];

  const isActive = (route: string) => pathname === route;

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openedMixin = (themeOptions: Theme): CSSObject => {
    const { transitions } = themeOptions;

    return {
      width,
      transition: transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
    };
  };

  const closedMixin = (themeOptions: Theme): CSSObject => {
    const { transitions, spacing, breakpoints } = themeOptions;

    return {
      transition: transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `calc(${spacing(7)} + 1px)`,
      [breakpoints.up('sm')]: {
        width: `calc(${spacing(8)} + 1px)`,
      },
      [breakpoints.down('sm')]: {
        width: 0,
      },
    };
  };

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    (themeOptions) => ({
      width,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(themeOptions.open && {
        ...openedMixin(themeOptions.theme),
        '& .MuiDrawer-paper': openedMixin(themeOptions.theme),
      }),
      ...(!themeOptions.open && {
        ...closedMixin(themeOptions.theme),
        '& .MuiDrawer-paper': closedMixin(themeOptions.theme),
      }),
    }),
  );

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon color="primary" /> : <ChevronLeftIcon color="primary" />}
        </IconButton>
      </DrawerHeader>

      <List sx={{ mb: 'auto' }}>
        {navItems.map(({ title, to, icon }) => (
          <ListItem key={title} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              component={Link}
              to={to}
              onClick={() => setTimeout(handleDrawerClose, 100)}
              selected={isActive(to)}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <List>
        {footerNavItems.map(({ title, to, icon }) => (
          <ListItem key={to} component={Link} to={to} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={isActive(to)}
              onClick={() => setTimeout(handleDrawerClose, 100)}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box sx={{
        textAlign: 'center',
        p: 2,
      }}
      >
        <Link to="/" className="inline-block"><img src={logo} alt="Logo" className="w-16 inline-flex items-center lg:mx-auto" /></Link>
      </Box>
      <Box sx={{
        textAlign: 'center',
      }}
      >
        <LanguageSwitcher />
      </Box>
      <Box component="aside" className={`${open ? 'block' : 'hidden'} p-2 text-gray-500 text-sm whitespace-normal text-center`}>
        <p>
          &copy; 2023-
          {new Date().getFullYear()}
          {' '}
          Cattle Tag®
        </p>
        <p>
          {t('All rights reserved')}
          .
        </p>
      </Box>
    </Drawer>
  );
}
