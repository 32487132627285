import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Container, createTheme,
  Dialog, DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, ThemeOptions, ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import themeSettings from 'src/theme';
import { useAppSelector } from 'src/hooks';
import useActions from 'src/hooks/useActions';
import { iOS } from 'src/utils';
import useSessionStorage from 'src/hooks/useSessionStorage';
import { Scan, TLink } from 'src/types/link';
import logo from 'src/assets/logo.svg';
import UserCard from 'src/pages/Link/components/UserCard';
import { LinkType } from 'src/constants/enums';

function LinkPage() {
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const theme = useMemo(() => createTheme(themeSettings() as ThemeOptions), []);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { alias } = useParams();
  const { getItem, setItem } = useSessionStorage();
  const { getLinkByAlias, updateLinkScansByAlias, assignLink } = useActions();
  const { link, linkError } = useAppSelector((state) => state.links);
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (alias) {
      getLinkByAlias(alias);
    }
  }, []);

  const handleClose = () => {
    setOpenAssignModal(false);
  };

  const handleRedirect = (linkItem: TLink) => {
    const {
      type, url, appleUrl, googleUrl,
    } = linkItem;

    if (type === LinkType.REDIRECT && url) {
      window.location.replace(url);
    }

    if (type === LinkType.APPLICATION) {
      const applicationLink = iOS() ? appleUrl : googleUrl;

      if (applicationLink) {
        window.location.replace(applicationLink);
      }
    }
  };

  useEffect(() => {
    if (link && alias) {
      const scanData: Partial<Scan> = {
        userAgent: navigator.userAgent,
        referrer: document.referrer,
      };

      if (!getItem('ip')) {
        axios.get('https://api.ipify.org/?format=json').then((response) => {
          scanData.ip = response.data.ip;
          setItem('ip', response.data.ip);

          updateLinkScansByAlias({ alias, data: scanData });
          handleRedirect(link);
        });
      } else {
        handleRedirect(link);
      }
    }
  }, [link]);

  useEffect(() => {
    if (linkError) {
      if (linkError.status === 403) {
        if (user) {
          setOpenAssignModal(true);
        } else {
          navigate(`/auth/${alias}`);
        }
      } else if (linkError.status === 400) {
        navigate('/');
      }
    }
  }, [linkError]);

  const handleAssign = () => {
    if (alias && user?.id) {
      assignLink({ alias, userId: user.id });

      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  const renderNotFound = () => {
    if (linkError && linkError.status === 404) {
      return (
        <Box className="grid gap-4">
          <Alert severity="warning">{t('Link does not exist')}</Alert>
          <Link to="/"><Button fullWidth variant="contained">{t('To the homepage')}</Button></Link>
        </Box>
      );
    }

    return null;
  };

  const renderUser = () => {
    if (link && link.type === 'tag') {
      return (
        <Box className="p-1">
          <UserCard alias={link.alias} userBio={link.owner} />
        </Box>
      );
    }

    return null;
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="bg-black grid items-end justify-items-center min-h-full">
        <Container maxWidth="sm" disableGutters>
          {renderNotFound()}
          {renderUser()}
        </Container>
        <Link to="/">
          <img src={logo} alt="Logo" className="p-4 w-20" />
        </Link>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        open={openAssignModal}
        onClose={handleClose}
        aria-labelledby="assign-qr-dialog-title"
      >
        <DialogTitle id="assign-qr-dialog-title">
          {t('Do you want to attach this QR code to the current user?')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>{t('This QR code is not currently attached to any user. You can press \'Exit\' and attach this QR code to another user.')}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              navigate('/');
            }}
          >
            {t('Cancel')}
          </Button>
          <Button variant="contained" onClick={handleAssign} autoFocus>
            {t('Attach')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default LinkPage;
