import { LinkType } from 'src/constants/enums';
import React from 'react';
import {
  Box,
  Chip,
  IconButton,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import { TLink } from 'src/types/link';
import { useTranslation } from 'react-i18next';
import useActions from 'src/hooks/useActions';
import CopyTextField from 'src/components/UI/CopyTextField';

const getLinkIcon = (type: LinkType) => {
  switch (type) {
    case LinkType.TAG:
      return <LoyaltyOutlinedIcon fontSize="large" color="secondary" />;
    case LinkType.APPLICATION:
      return <AppSettingsAltOutlinedIcon fontSize="large" color="secondary" />;
    case LinkType.REDIRECT:
    default:
      return <LinkOutlinedIcon fontSize="large" color="secondary" />;
  }
};

interface UserLinkItemProps {
  link: TLink,
}

function UserLinkItem({ link }: UserLinkItemProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { updateLink, deleteLink } = useActions();
  const {
    id,
    title,
    type,
    url,
    googleUrl,
    appleUrl,
    huaweiUrl,
    alias,
    active,
    clicks,
  } = link;

  const handleActiveState = (e: React.FormEvent) => {
    e.preventDefault();

    updateLink({ id, data: { active: !active } });
  };

  const renderChips = (linkType: LinkType) => {
    switch (linkType) {
      case LinkType.REDIRECT:
        return (
          <Chip
            className="overflow-hidden"
            color="secondary"
            variant="filled"
            label={url}
          />
        );
      case LinkType.APPLICATION:
        return (
          <Box className="grid gap-2">
            <Chip
              className="overflow-hidden"
              color="secondary"
              label={googleUrl}
            />
            <Chip
              className="overflow-hidden"
              color="secondary"
              label={appleUrl}
            />
            <Chip
              className="overflow-hidden"
              color="secondary"
              label={huaweiUrl}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <li>
      <Box
        className="grid gap-2 p-6 rounded"
        sx={{ backgroundColor: theme.palette.primary.main }}
      >
        <Box className="flex gap-2 items-center overflow-hidden">
          {getLinkIcon(link.type)}
          <Typography
            color="secondary"
            className="flex-1 whitespace-nowrap text-ellipsis overflow-hidden"
          >
            {title}
          </Typography>
        </Box>
        {renderChips(type)}
        <CopyTextField
          name={id}
          prefixValue={`${window.location.origin.toString()}/`}
          value={alias || ''}
          copyText={`${window.location.origin.toString()}/${link.alias}`}
        />
        <Box className="flex gap-2 items-center">
          <Switch
            inputProps={{ 'aria-label': t('Activate') }}
            checked={active}
            id={id}
            color="secondary"
            onClick={handleActiveState}
          />
          <Typography color="secondary">
            {t('%n views').replace('%n', String(clicks))}
          </Typography>
          <Box className="flex items-center ml-auto">
            <Link to={`/link-edit/${id}`}>
              <IconButton aria-label="Analytics">
                <SignalCellularAltOutlinedIcon color="secondary" />
              </IconButton>
            </Link>
            {type !== LinkType.TAG && (
              <IconButton
                onClick={() => deleteLink(id)}
                aria-label={t('Delete')}
                color="error"
              >
                <DeleteOutlinedIcon color="secondary" />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </li>
  );
}

export default UserLinkItem;
